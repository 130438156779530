import { useEffect, useState } from "react";
import useCheckInet from "../hook/useCheckInet";
import useUser from "../hook/useUser";
import axios from "axios";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";
import useEvent from "../hook/useEvent";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import moment from "moment";

const StatusInet = (props) => {
  const [infoSync, setInfoSync] = useState(false);
  const [infoOnline, setInfoOnline] = useState(false);
  const [infoOffline, setInfoOffline] = useState(false);
  const [sukses, setSukses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const checkInet = useCheckInet();

  const localUser = useUser();
  const localEvent = useEvent();

  useEffect(() => {
    if (checkInet.isOnline) {
      let redeem = window.localStorage.getItem("LocalRedeem");

      if (redeem) {
        if (Cookies.get("token")) {
          setInfoOnline(true);
          setInfoOffline(false);
        }
      } else {
        let users = window.localStorage.getItem("LocalUser");
        if (users === null || users === undefined || users === "undefined") {
          localUser.fetchData();
        }
      }
    } else {
      setInfoOnline(false);
      setInfoOffline(true);
    }
  }, [checkInet.isOnline]);

  const handleSync = () => {
    if (!checkInet.isOnline) {
      closeAllInfo();
      return;
    }

    setInfoOnline(false);
    setMsg("Sync Process, Please Wait..");
    setInfoSync(true);
    setLoading(true);
    setSukses(false);

    let redeem = window.localStorage.getItem("LocalRedeem");
    const json = {
      json_redeem: redeem !== null ? JSON.parse(redeem) : [],
    };

    const payload = {
      json: JSON.stringify(json),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/sync", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          window.localStorage.removeItem("LocalRedeem");

          setMsg("Sync Success");
          setSukses(true);
          localUser.fetchData();
          props.checkLocal();
        } else {
          setMsg("Sync Failed, please try again!");
          setSukses(false);
        }
      })
      .catch(function (error) {
        setMsg("Sync Failed, please try again!");
        setLoading(false);
        setSukses(false);
      });
  };

  const closeAllInfo = () => {
    setInfoSync(false);
    setInfoOnline(false);
    setInfoOffline(false);
    setSukses(false);
    setLoading(false);
  };

  const exportData = () => {
    let redeem = window.localStorage.getItem("LocalRedeem");

    const data = {
      json_redeem: redeem !== null ? JSON.parse(redeem) : [],
    };

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `data_offline_${moment(Date.now()).format(
      "YYYY-MM-DD_hh-mm-ss"
    )}.json`;

    link.click();
  };

  return (
    <>
      {infoSync && (
        <>
          <div className="z-[99999] flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000cc] text-white justify-center items-center font-semibold text-xl">
            <>
              <span className="text-white-500 font-bold  text-center mb-5 px-2">
                {msg}
              </span>

              {!loading && (
                <>
                  {sukses ? (
                    <button
                      onClick={() => closeAllInfo()}
                      className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                    >
                      OK
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSync()}
                      className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                    >
                      SYNC AGAIN
                    </button>
                  )}
                </>
              )}
            </>
          </div>
        </>
      )}

      {infoOnline && (
        <>
          <div className="z-[99999] flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000cc] text-white justify-center items-center font-semibold text-xl">
            <>
              <span className="text-green-500 font-bold text-center mb-5">
                INFO:
              </span>
              <span className="text-white-500 font-bold  text-center mb-5 px-2">
                Your connection is online, please synchronize data first
              </span>
              <button
                onClick={() => handleSync()}
                className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
              >
                SYNC NOW
              </button>
            </>
          </div>
        </>
      )}

      {infoOffline && (
        <>
          <div className="z-[99999] flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000cc] text-white justify-center items-center font-semibold text-xl">
            <>
              <span className="text-red-500 font-bold text-center mb-5">
                INFO:
              </span>
              <span className="text-white-500 font-bold  text-center mb-5 px-2">
                Your connection is offline, the application can still be used
                but please remember you have to synchronize data when your
                connection is online again
              </span>
              <button
                onClick={() => setInfoOffline(false)}
                className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
              >
                OK
              </button>
            </>
          </div>
        </>
      )}

      <div className="fixed w-full">
        <div
          className={`px-4 py-2 text-sm flex justify-between items-center text-white  w-full ${
            checkInet.isOnline ? "bg-green-700" : "bg-red-700"
          }`}
        >
          <div>{checkInet.isOnline ? "ONLINE" : "OFFLINE"}</div>
          <div>
            <div className="flex space-x-2">
              {props?.totalRedeem > 0 && <div>Redeem: {props.totalRedeem}</div>}

              {props?.totalRedeem > 0 && (
                <div className="cursor-pointer" onClick={() => exportData()}>
                  <BsFileEarmarkArrowDownFill color="white" size={20} />
                </div>
              )}
            </div>
          </div>
        </div>

        {checkInet.isOnline &&
          Cookies.get("token") &&
          props?.totalRedeem > 0 && (
            <div className="px-2 w-full mt-2">
              <button
                onClick={() => handleSync()}
                className="w-full min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
              >
                SYNC NOW
              </button>
            </div>
          )}
      </div>
    </>
  );
};

export default StatusInet;
