import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import useCheckInet from "../../hook/useCheckInet";
import useReport from "../../hook/useReport";

const FormRFIDResult = (props) => {
  const localUser = useUser();
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [showGuest, setShowGuest] = useState(false);
  const [listGuest, setListGuest] = useState([]);
  const [gate, setGate] = useState(null);
  const [redeemItem, setRedeemItem] = useState([]);

  const checkInet = useCheckInet();

  const localReport = useReport();

  // useEffect(() => {
  //   if (props.user.event_gate) {
  //     let _gate = props.user.event_gate?.find((v) => v.id_gate === "2");
  //     setGate(_gate);
  //   }
  // }, [props?.user]);

  // useEffect(() => {
  //   localReport.fetchData();
  // }, []);

  useEffect(() => {
    initAllowed();
  }, [props.user]);

  const initItemRedeem = () => {
    let arr = [...props.user?.event_redeem];
    let _arr = arr.filter((v) => v.redeem_time !== "0000-00-00 00:00:00");
    let status = false;

    if (_arr.length === 2) {
      status = true;
    }

    let __arr = arr.map((v) => {
      return {
        ...v,
        collected: v.redeem_time !== "0000-00-00 00:00:00" ? true : false,
        all_collected: status,
      };
    });

    setRedeemItem(__arr);
  };

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         handleCheckin();
  //       }, 1000);
  //       return;
  //     } else {
  //       if (checkAllow !== "allowed-to-re-checkin") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 1500);
  //         return;
  //       }
  //     }
  //   }
  // }, [checkAllow]);

  const togleGuest = (idx) => {
    let arr = [...listGuest];
    arr[idx].active = !arr[idx].active;
    setListGuest(arr);
  };

  const onSubmit = () => {
    if (loading) return;

    setLoading(true);

    if (checkAllow === "allowed-to-checkin") {
      handleCheckin();
    } else {
      handleReentry();
    }
  };

  const handleCheckin = () => {
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkin", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleReentry = () => {
    const payload = {
      id_gate: query.get("id_gate"),
      rfid: props?.user?.rfid,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/rfidx/reentry", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (props.user?.notfound) {
      setCheckAllow("user-not-found");
    } else {
      initItemRedeem();

      let _arr = props.user?.event_redeem.filter(
        (v) => v.redeem_time !== "0000-00-00 00:00:00"
      );
      let status = false;

      if (_arr.length === 2) {
        status = true;
      }

      if (status) {
        setCheckAllow("already-collected");
      } else {
        setCheckAllow("allowed-to-collect");
      }
    }

    // if (props.user.exit_time !== undefined) {
    //   setCheckAllow("allowed-to-re-checkin-new");
    // } else {
    //   if (gate !== null) {
    //     if (props.user.access_privilege.toLowerCase().includes(gate.gate.toLowerCase())) {
    //       if (gate?.checkin_time !== "0000-00-00 00:00:00") {
    //         setCheckAllow("already-checkin");
    //       } else {
    //         setCheckAllow("allowed-to-checkin");
    //       }
    //     }
    //   } else {
    //     setCheckAllow("wrong-gate");
    //   }
    // }
  };

  const getCheckinTime = () => {
    if (Date.parse(gate?.reentry_time) > Date.parse(gate?.checkin_time)) {
      return gate?.reentry_time;
    } else {
      return gate?.checkin_time;
    }
  };

  const handleShowGuest = () => {
    if (showGuest) {
      setListGuest([]);
      setShowGuest(false);
    } else {
      setListGuest([
        {
          label: "Guest 1",
          active: true,
        },
        {
          label: "Guest 2",
          active: false,
        },
        {
          label: "Guest 3",
          active: false,
        },
      ]);
      setShowGuest(true);
    }
  };

  const checkCollected = (obj) => {
    if (obj.redeem_time !== "0000-00-00 00:00:00") {
      return {
        ...obj,
        collected: true,
      };
    } else {
      return {
        ...obj,
        collected: false,
      };
    }
  };

  const toggleRedeem = (id) => {
    let arr = [...redeemItem];
    let item = arr.find((v) => v.id_redeem === id && !v.all_collected);

    if (item !== undefined) {
      if (!item.collected) {
        if (checkInet.isOnline) {
          redeemOnline(id);
        } else {
          let time = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

          item.collected = !item.collected;
          item.redeem_time = time;
          setRedeemItem(arr);

          let localRedeem = window.localStorage.getItem("LocalRedeem");
          let localArr = [];
          if (localRedeem) {
            localArr = [...JSON.parse(localRedeem)];
          }

          let redeem = {
            id_user: props.user.id_user,
            id_redeem: id,
            redeem_gate: query.get("gate"),
            redeem_item: props.user.meal_choice.toUpperCase(),
            redeem_time: time,
          };

          localArr.push(redeem);

          window.localStorage.setItem("LocalRedeem", JSON.stringify(localArr));
          props.checkLocalRedeem();

          //update local user
          let users = window.localStorage.getItem("LocalUser");
          users = JSON.parse(users);
          let _users = [...users];
          let _user = _users.find((v) => v.id_user === props.user.id_user);
          let _redeem = _user.event_redeem.find((v) => v.id_redeem === id);

          if (_redeem !== undefined) {
            _redeem.redeem_gate = query.get("gate");
            _redeem.redeem_item = props.user.meal_choice.toUpperCase();
            _redeem.redeem_time = time;
          }

          window.localStorage.setItem("LocalUser", JSON.stringify(_users));

          setTimeout(() => {
            props.ok()
          }, 500);
          //update local user
        }
      }
    }
  };

  const redeemOnline = async (id_redeem) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      id_user: props.user.id_user,
      id_redeem: id_redeem,
      redeem_gate: query.get("gate"),
      redeem_item: props.user.meal_choice.toUpperCase(),
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/redeem", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);

          if (response?.data.status === "SUCCESS") {
            let arr = [...redeemItem];
            let item = arr.find(
              (v) => v.id_redeem === id_redeem && !v.all_collected
            );

            if (item !== undefined) {
              if (!item.collected) {
                item.collected = !item.collected;
                item.redeem_time = response?.data.redeem_time;
                setRedeemItem(arr);
              }
            }

            localUser.fetchData();

            setTimeout(() => {
              props.ok()
            }, 500);
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const calcCheckin = () => {
    if (localReport?.data) {
      const { checkin, exit, reentry } = localReport?.data;

      let total = checkin - exit + reentry;

      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }

    return 0;
  };

  return (
    <>
      {loading && (
        <div className="text-white font-bold fixed z-10 w-screen h-screen flex justify-center items-center bg-[#000000bd]">
          Loading...
        </div>
      )}
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="text-xl uppercase">{query.get("gate")}</div>
          {/* {checkAllow !== "user-not-found" && (
            <div className="text-sm uppercase">COLLECTED: {calcCheckin()}</div>
          )} */}
        </div>

        {checkAllow === "allowed-to-collect" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO COLLECT
          </div>
        )}

        {checkAllow === "user-not-found" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            INVALID
          </div>
        )}

        {checkAllow === "already-collected" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY COLLECTED
          </div>
        )}

        {checkAllow === "allowed-to-checkin" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO ENTER
          </div>
        )}

        {checkAllow === "allowed-to-re-checkin" ||
          (checkAllow === "allowed-to-re-checkin-new" && (
            <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
              ALLOWED TO RE-ENTRY
            </div>
          ))}

        {checkAllow === "already-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY CHECKIN : {getCheckinTime()}
          </div>
        )}

        {checkAllow === "not-allowed-to-checkin" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            NOT ALLOWED TO CHECKIN
          </div>
        )}

        {checkAllow === "wrong-gate" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            WRONG GATE
          </div>
        )}

        {/* <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
          ALREADY CHECKED IN
        </div> */}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {props.user?.rfid !== "" && (
                <>
                  <div className="text-center text-xl font-bold text-black uppercase mb-0">
                    {props.user.username}
                  </div>

                  {!props?.user?.notfound && (
                    <>
                      <div className="text-center text-sm font-bold text-black uppercase mb-2">
                        <div className="text-black font-normal capitalize">
                          Section:
                        </div>
                        <div>{props.user.section}</div>
                      </div>

                      <hr className="w-full h-0.5 my-1 bg-gray-200" />

                      <div className="text-center text-4xl font-bold text-black uppercase mb-0">
                        {props.user.meal_choice}
                      </div>

                      <div className="flex flex-col space-y-5 mt-5">
                        {redeemItem?.map((v, idx) => {
                          return (
                            <>
                              <button
                                key={idx}
                                type="button"
                                onClick={() => toggleRedeem(v.id_redeem)}
                                className={`${
                                  v.collected ? "bg-gray-500" : "bg-green-500"
                                } min-w-[160px] relative px-4 py-2 rounded-lg text-white font-bold  w-full text-center`}
                              >
                                <div>{v.redeem}</div>

                                {v.redeem_time !== "0000-00-00 00:00:00" && (
                                  <div className="font-normal">
                                    <div>Collected at {v.redeem_gate}:</div>
                                    <div>{v.redeem_time}</div>
                                  </div>
                                )}
                              </button>
                            </>
                          );
                        })}
                      </div>

                      <hr className="w-full h-0.5 mt-4 bg-gray-200" />
                    </>
                  )}
                </>
              )}
              {/* <div className="text-center text-3xl font-bold text-black uppercase mt-4">
                {props.user.event || ""}
              </div> */}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <>
          <div className="mx-auto flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={() => props.ok()}
              className={`px-10 py-2 rounded-lg text-white font-bold bg-orange-500  w-full text-center`}
            >
              BACK
            </button>

            {(checkAllow === "allowed-to-re-checkin" ||
              checkAllow === "allowed-to-re-checkin-new" ||
              checkAllow === "allowed-to-checkin") && (
              <button
                type="button"
                onClick={() => onSubmit()}
                className={`px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
              >
                {loading ? "Loading..." : "CONFIRM"}
              </button>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FormRFIDResult;
