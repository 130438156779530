import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import useCheckInet from "../../hook/useCheckInet";

const FormVerification = (props) => {
  const checkInet = useCheckInet();
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("rfid");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const [showQR, setShowQR] = useState(props.showQR || false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const query = useQuery();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount(count + 1);
  //     handleCheckinTime();
  //   }, 1000 * 5);

  //   return () => clearInterval(interval);
  // }, [count]);

  // useEffect(() => {
  //   localReport.fetchData();
  // }, []);

  const handleCheckinTime = async () => {
    const response = await axios.get(
      CONFIG.URL + "/event/report/currentcheckin"
    );

    let localTime = Cookies.get("local_time");
    if (localTime && localTime !== undefined && localTime !== "undefined") {
      if (moment(response?.data?.last_update) > moment(localTime)) {
        localReport.fetchData();
        Cookies.set("local_time", response?.data?.last_update);
      }
    } else {
      Cookies.set("local_time", response?.data?.last_update);
      localReport.fetchData();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    if (focus === "name") {
      checkName(data);
    } else if (focus === "rfid") {
      if (checkInet.isOnline) {
        checkRFID(data);
      } else {
        checkScan(data);
      }
    } else if (focus === "rfid_reentry") {
      checkRFIDREEntry(data);
    } else if (focus === "qr_code") {
      checkQRCode(data);
    }
    return;
  };

  const checkScan = (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    let users = window.localStorage.getItem("LocalUser");
    users = JSON.parse(users);

    let _user = users.find((v) => v.qr_code === data.rfid);

    if (_user !== undefined) {
      props.onSuccess(_user, "rfid");
    } else {
      props.onSuccess(
        {
          username: "USER NOT FOUND",
          section: "",
          notfound: true,
        },
        "rfid"
      );
    }

    setLoading(false);
  };

  const checkRFIDREEntry = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/event/rfidx/" + data.rfid_reentry, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);

          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "rfid");
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkRFID = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    const payload = {
      code: data.rfid,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/qrcode", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);

          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "rfid");
          } else {
            props.onSuccess(
              {
                username: "USER NOT FOUND",
                section: "",
                notfound: true,
              },
              "rfid"
            );
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkQRCode = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.qr_code, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "qr_code", data.qr_code);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const checkName = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    try {
      axios
        .get(CONFIG.URL + "/user/" + data.rfid, {
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess(response?.data?.data[0], "rfid");
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("name", "");
    setValue("rfid", "");
    setValue("qr_code", "");
    setValue("rfid_reentry", "");
    reset();
  };

  const calcCheckin = () => {
    if (localReport?.data) {
      const { checkin, exit, reentry } = localReport?.data;

      let total = checkin - exit + reentry;

      if (total < 0) {
        return 0;
      } else {
        return total;
      }
    }

    return 0;
  };

  const handleCloseScan = () => {
    setShowQR(false);
  };

  const handleSuccess = (qr, online) => {
    handleCloseScan();

    if (online) {
      checkRFID({
        rfid: qr,
      });
    } else {
      checkScan({
        rfid: qr,
      });
    }

    //checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
  };

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  return (
    <>
      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4 z-0"
      >
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl uppercase">{query.get("gate")}</div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {/* <button
            type="button"
            onClick={() => setShowQR(true)}
            className={`text-4xl md:min-w-[160px] uppercase whitespace-nowrap px-2 py-10 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            Scan QR DESKTOP
          </button> */}

          {/* <div className="flex justify-center mt-10">
            <div
              onClick={() => navigate("/gate")}
              className={`cursor-pointer min-w-[160px] w-max px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
            >
              BACK
            </div>
          </div> */}

          {/* SCAN QR CODE */}
          {/* <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan QR CODE
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
            autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div> */}

          {/* SCAN QR CODE / RFID */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan QR Code
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
              autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("rfid", { required: false })}
            />
          </div>

          {/* SCAN QR CODE / RFID */}
          {/* <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan RFID RE-ENTRY
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid_reentry")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("rfid_reentry", { required: false })}
            />
          </div> */}
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        {/* {focus === "name" && (
          <div className="grid grid-cols-2 gap-5">
            <button
              type="button"
              onClick={() => setShowQR(true)}
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              Scan QR
            </button>
            <button
              type="submit"
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )} */}

        {focus === "rfid" && (
          <div className="grid grid-cols-1 gap-5">
            {/* <button
              type="button"
              onClick={() => setShowQR(true)}
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              Scan QR
            </button> */}
            <button
              type="submit"
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}

        {focus === "rfid_reentry" && (
          <div className="grid grid-cols-1 gap-5">
            {/* <button
              type="button"
              onClick={() => setShowQR(true)}
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              Scan QR
            </button> */}
            <button
              type="submit"
              className={`md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}

        {focus === "qr_code" && (
          <button
            type="submit"
            className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-purple-500  w-full text-center`}
          >
            {loading ? "Loading..." : "Submit Check QR CODE"}
          </button>
        )}
      </form>
    </>
  );
};

export default FormVerification;
