import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";
import helper from "../../utils/helper";
import axios from "axios";
import CONFIG from "../../constanta/config";

const PageEvent = (props) => {
  const query = useQuery();
  const [event, setEvent] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./login";
    }

    // getGate();
  }, []);

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  const lisRedeem = [
    "The Kitchen TT",
    "Aunty Cathy’s",
    "EAT ITT",
    "Christos",
    "Shi Bites",
    "PAPADUMS",
    "WHIPPED",
    "Trill of the Grill",
    "Dessert Genie",
    "Meal Gate 10",
  ];

  const getGate = () => {
    axios
      .get(CONFIG.URL + "/event/redeem", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setEvent(response.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-start items-center pt-5 bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url('${helper.getBackground(
            props?.setting?.background
          )}')`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20">
          <div className="mb-10 grid grid-cols-2 gap-5 w-full">
            {lisRedeem.map((v, index) => {
              return (
                <div key={index} className="flex flex-col space-y-2">
                  <div className="text-center uppercase text-white font-bold text-xl mb-2">
                    {v}
                  </div>

                  <div
                    onClick={() => navigate(`/redeem-mobile?gate=${v}`)}
                    className={`cursor-pointer min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
                  >
                    REDEMPTION - MOBILE
                  </div>

                  <div
                    onClick={() => navigate(`/redeem-desktop?gate=${v}`)}
                    className={`cursor-pointer min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-orange-500 w-full text-center`}
                  >
                    REDEMPTION - DESKTOP
                  </div>
                </div>
              );
            })}
          </div>

          <div className="w-full">
            <div className="border border-white my-5"></div>
          </div>

          <div
            onClick={() => navigate("/home")}
            className={`cursor-pointer min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
          >
            BACK
          </div>
        </div>
      </div>
    </>
  );
};

export default PageEvent;
